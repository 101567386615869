import {sortBy} from 'lodash';
import {
  type VettableCreators,
  type VettableCreator,
} from '@/types/vettableCreatorsResponse';

// Helper function to normalize URLs by removing trailing slashes
function normalizeUrl(url: string | undefined): string {
  return url?.replace(/\/+$/, '') ?? '';
}

// Priority mapping for provider sorting
export const providerPriority: Record<string, number> = {
  instagram: 1,
  tiktok: 2,
  youtube: 3,
  facebook: 4,
  other: 5,
};

// Check if a creator’s account is already related in the combined results
function isCreatorRelated(
  creator: VettableCreator,
  combinedCreators: VettableCreator[]
): boolean {
  return Boolean(
    combinedCreators.some((cc) =>
      cc.relatedAccounts?.some(
        (ra) => normalizeUrl(ra.url) === normalizeUrl(creator.accountUrl)
      )
    ) ||
      creator.relatedAccounts?.some((ra) =>
        combinedCreators.some(
          (cc) => normalizeUrl(cc.accountUrl) === normalizeUrl(ra.url)
        )
      )
  );
}

// Main function to combine related creators
function combineRelatedCreators(
  data: VettableCreators
): VettableCreators<VettableCreator> {
  const combinedCreators: VettableCreator[] = [];

  const sortedOffPlatform = sortBy(
    data.offPlatform,
    (creator) => providerPriority[creator.provider ?? 'other']
  );

  sortedOffPlatform.forEach((creator) => {
    if (!isCreatorRelated(creator, combinedCreators)) {
      combinedCreators.push(creator);
    }
  });

  // Return the modified data with combined creators in offPlatform
  return {...data, offPlatform: combinedCreators};
}

export default combineRelatedCreators;
